import api from '@/api';

type ContactFormData = {
  name: string;
  email: string;
  message: string;
}

const submitContactForm = async (formData: ContactFormData) => {
  return await api.post('/contact-us-landing', formData);
};

export { submitContactForm };