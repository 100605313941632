'use client';

import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { CgCloseR } from 'react-icons/cg';

import { submitContactForm } from '@/api/contact-us';

const ContactForm = () => {
  const [response, setResponse] = useState(null);

  const { mutate, isPending } = useMutation({
    mutationFn: submitContactForm,
    onSuccess: (result) => {
      setResponse(result?.data?.message);
    },
    onError: () => {
      console.error('Error submitting form');
    },
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    subject: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutate(formData);
  };

  return (
    <div className="w-full bg-gray-200 p-6 px-4 rounded-lg shadow-lg self-end ml-auto md:mx-12 mx-0">
      <h2 className="text-2xl font-normal text-gray-800 mb-6 text-center">Contact Us</h2>

      {response &&
        <div className="bg-green-100 border text-start border-green-400 text-green-700 px-4 py-3 mb-3 rounded relative" role="alert">
          <span className="block sm:inline">{response}</span>
          <button type="button" className="absolute top-0 bottom-0 right-0 px-4 py-3" aria-label="Close" onClick={() => setResponse(null)} >
            <span className="text-gray-700 font-bold"><CgCloseR /></span>
          </button>
        </div>
      }

      <form onSubmit={handleSubmit} className="space-y-4 text-start">
        <div>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 mt-1 border focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg"
          />
        </div>
        <div>
          <input
            type="text"
            name="phone_number"
            placeholder="Your Phone Number"
            value={formData.phone_number}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 mt-1 border focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg"
          />
        </div>
        <div>
          <input
            type="text"
            name="subject"
            placeholder="Make & Model"
            value={formData.subject}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 mt-1 border focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg"
          />
        </div>
        <div>
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 mt-1 border  focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg"
          />
        </div>
        <div>
          <textarea
            name="message"
            placeholder="Describe your fault"
            value={formData.message}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 mt-1 border focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg"
          />
        </div>
        <button
          type="submit"
          disabled={isPending}
          className="w-full bg-sky-500/100 rounded-lg text-white py-2 hover:bg-gray-700 hover:text-white transition duration-300"
        >
          {isPending ? 'Sending...' : 'Submit'}
        </button>

      </form>
    </div>
  );
};

export default ContactForm;